<template>
  <div class="index container">
    <transition name="fade">
      <router-view @showParent="showParent"> </router-view>
    </transition>

    <div v-if="showParentPage">
      <v-breadcrumbs>
        <v-breadcrumbs-item :exact="true" :to="{ name: 'home', params: {} }"
          >Home</v-breadcrumbs-item
        >
        <v-breadcrumbs-divider>/</v-breadcrumbs-divider>
        <v-breadcrumbs-item
          :exact="true"
          :to="{ name: 'company', params: {} }"
          active
        >
          Company
        </v-breadcrumbs-item>
      </v-breadcrumbs>

      <div class="d-flex">
        <label class="mt-1 mx-2 font-weight-light">Company Type: </label>
        <v-radio-group
          @change="changeType()"
          class="my-auto"
          v-model="type"
          row
        >
          <v-radio value="Supplier" label="Supplier"></v-radio>
          <v-radio value="Customer" label="Customer"></v-radio>
          <v-radio value="Own" label="Own"></v-radio>
        </v-radio-group>
      </div>
      <div class="d-flex flex-column flex-lg-row">
        <v-text-field
          class="my-auto"
          dense
          v-model="searchTerms"
          @keyup.enter="search"
          @click:clear="clearSearch"
          @click:append="search"
          placeholder="Press enter to begin search"
          append-icon="mdi-magnify"
          outlined
          clearable
          label="Search"
        />
        <v-btn
          class="ml-0 ml-lg-4 mb-2 mb-lg-0 align-self-end align-self-lg-start"
          color="primary"
          :to="{ name: 'companyCreate', params: {} }"
        >
          Create Company
        </v-btn>
      </div>

      <div class="table-container">
        <v-data-table
          v-if="loaded"
          :headers="headers"
          :items="companyList"
          :items-per-page="pagination.pageSize"
          :loading="!companyProcessed"
          :server-items-length="pagination.totalSize"
          :page="pagination.current"
          :disable-pagination="!companyList || companyList.length < 1"
          :hide-default-header="!companyList || companyList.length < 1"
          :hide-default-footer="!companyList || companyList.length < 1"
          :options.sync="options"
          item-key="id"
          class="elevation-1"
          @update:options="pageChanged"
        >
          <template v-slot:item.name="{ item }">
            <a @click="view(item)">{{ item.name }}</a>
          </template>

          <template v-slot:item.type="{ item }">
            <a @click="view(item)">{{ item.type }}</a>
          </template>

          <template v-slot:item.email="{ item }">
            <a @click="view(item)">{{ item.email }}</a>
          </template>

          <template v-slot:item.address="{ item }">
            <a @click="view(item)">{{ item.address }}</a>
          </template>

          <template v-slot:item.phone1="{ item }">
            <a @click="view(item)">{{ item.phone1 }}</a>
          </template>

          <template v-slot:item.phone2="{ item }">
            <a @click="view(item)">{{ item.phone2 }}</a>
          </template>

          <template v-slot:item.actions="{ item }">
            <v-icon small class="mr-2" @click="edit(item)"> mdi-pencil </v-icon>
            <v-icon small class="mr-2" @click="remove(item)">
              mdi-delete
            </v-icon>
          </template>
        </v-data-table>
      </div>
    </div>
    <confirmation-dialog ref="confirm" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      pagination: {
        current: 1,
        pageSize: 10,
        totalSize: 100,
      },
      options: {
        //define default sorting option here.
        sortBy: [],
        sortDesc: [],
      },
      companyList: [],
      showParentPage: true,
      type: "Supplier",
      loaded: false,
      headers: [
        {
          text: "Name",
          value: "name",
          align: "start",
          sortable: true,
        },
        {
          text: "Type",
          value: "type",
          align: "start",
          sortable: true,
        },
        {
          text: "Email",
          value: "email",
          align: "start",
          sortable: true,
        },
        {
          text: "Address",
          value: "address",
          align: "start",
          sortable: false,
        },
        {
          text: "Phone 1",
          value: "phone1",
          align: "start",
          sortable: false,
        },
        {
          text: "Phone 2",
          value: "phone2",
          align: "start",
          sortable: false,
        },

        { text: "Actions", value: "actions", align: "end", sortable: false },
      ],
      sort: {
        key: null,
      },
      searchTerms: "",
    };
  },
  computed: {
    ...mapGetters("company", ["companyProcessed"]),
  },
  mounted() {
    const type = this.$route.query.type;
    if (type) {
      this.type = type;
    }

    this.loaded = true;
  },
  methods: {
    ...mapActions("messages", [
      "addErrorMessage",
      "addMessage",
      "addSuccessMessage",
    ]),
    ...mapActions("company", [
      "deleteCompany",
      "fetchSuppliers",
      "fetchCustomers",
      "fetchOwn",
    ]),
    view(item) {
      this.$router.push({ name: "companyView", params: { id: item.id } });
    },
    edit(item) {
      this.$router.push({ name: "companyEdit", params: { id: item.id } });
    },
    changeType() {
      this.$router
        .replace({ name: "company", query: { type: this.type } })
        .catch(() => {});
    },
    async remove(item) {
      let confirm = await this.$refs.confirm.open(
        "Confirm Delete",
        "Do you want to delete this company?"
      );
      if (confirm) {
        this.deleteCompany(item.id)
          .then(() => {
            this.addSuccessMessage("company was deleted successfully");
            this.getResources();
          })
          .catch(() => {
            this.addErrorMessage("Server response with error ");
          });
      }
    },
    getResources() {
      //default to sorting by ID desc if not defined
      const sortBy =
        this.options["sortBy"] && this.options["sortBy"].length > 0
          ? this.options.sortBy[0]
          : "id";
      const sortDesc =
        this.options["sortDesc"] && this.options["sortDesc"].length > 0
          ? this.options.sortDesc[0]
          : true;
      const params = {
        page:
          this.pagination.pageIndex && this.pagination.pageIndex > 0
            ? this.pagination.pageIndex - 1
            : 0,
        size: this.pagination.pageSize,
        sort: `${sortBy}${sortDesc ? ",desc" : ""}`,
        name: this.searchTerms ? this.searchTerms.trim() : null,
        thaiName: this.searchTerms ? this.searchTerms.trim() : null,
      };

      if (this.type == "Supplier") {
        this.fetchSuppliers(params)
          .then((response) => {
            if (response) {
              this.pagination.totalSize = response.totalElements;
              this.companyList = response.content;
            }
          })
          .catch((error) => {
            this.addErrorMessage("Server response with error " + error);
          });
      } else if(this.type == 'Customer'){
        this.fetchCustomers(params)
          .then((response) => {
            if (response) {
              this.pagination.totalSize = response.totalElements;
              this.companyList = response.content;
            }
          })
          .catch((error) => {
            this.addErrorMessage("Server response with error " + error);
          });
      }
      else{ 
        this.fetchOwn()
          .then((company) => {
            if (company) {
              this.pagination.totalSize = 1;
              this.companyList = [company]
            }
          })
          .catch((error) => {
            this.addErrorMessage("Server response with error " + error);
          });
      }
    },
    showParent(show) {
      this.showParentPage = show;
    },
    pageChanged(pagination) {
      this.pagination.pageIndex = pagination.page;
      this.pagination.pageSize = pagination.itemsPerPage;

      this.getResources();
    },
    search() {
      this.getResources();
    },
    clearSearch() {
      this.searchTerms = null;
      this.getResources();
    },
  },
  watch: {
    "pagination.numberRegisterForPage": function () {
      this.pagination.current = -1;
    },
  },
  beforeRouteUpdate(to, from, next) {
    const toDepth = to.path.split("/").length;
    const fromDepth = from.path.split("/").length;
    this.showParentPage = toDepth < fromDepth;
    if (this.showParentPage) {
      this.getResources();
    }
    next();
  },
};
</script>
